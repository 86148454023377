<template>
  <LxpMobileHeader v-if="isMobile && isAuthentication" title="KB 국민인증서 인증" />
  <!--  <main class="kb-main" id="kb-login-sub">-->
  <main class="kb-main" :id="[isMobile ? 'kb-login-auth' : 'kb-login-sub']">
    <!-- main-header -->
    <div v-if="!isMobile && isUserInfo" class="main-header">
      <!-- main-header > header-top -->
      <div class="header-top main-component">
        <div class="header-column">
          <a href="javascript:" class="page-nav" @click="goSignIn">
            <i class="icon-kb-nav-arrow"></i>
            <span class="text">이전</span>
          </a>
        </div>
      </div>
      <!-- main-header > header-title -->
      <div class="header-title">
        <h2 class="title">{{ headerTitle }}</h2>
      </div>
    </div>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content main-component">
      <div :class="{'login-view-container' : isMobile}">
        <div :class="{'view-contents' : isMobile}">

          <section v-if="isUserInfo" class="content-section">
            <header class="section-header">
              <h4 class="title">정보 확인</h4>
            </header>

            <div v-if="isMobile" class="section-content">
              <div class="content-item">
                <AuthFormField label-text="직원 정보">
                  <template v-slot:inner>
                    <div class="kb-form-text">{{ userInfo.deptNm }} {{ userInfo.lrnerNm }} ({{ userInfo.lrnerId }})</div>
                  </template>
                </AuthFormField>
              </div>
            </div>
            <AuthFormField v-else label-text="직원 정보">
              <template v-slot:inner>
                <div class="kb-form-column"><span class="kb-form-text">{{ userInfo.deptNm }} {{ userInfo.lrnerNm }} ({{ userInfo.lrnerId }})</span></div>
              </template>
            </AuthFormField>
          </section>

          <section class="content-section" :class="{'section-forms section-divider' : !isMobile}">
            <header class="section-header">
              <h4 class="title">인증방법</h4>
            </header>

            <div v-if="isMobile" class="section-content">
              <div class="content-item">
                <div class="auth-list">
                  <ul>
                    <li class="auth-list-item">
                      <label for="result1" class="auth-list-target">
                        <span class="text bold">KB 국민인증서</span>
                        <span class="text">{{ userInfo.mblTelno }}</span>
                      </label>
                      <input v-model="authMethods" type="checkbox" class="kb-form-check-input" name="chkMember" id="result1" :value="notiPfmCds.kbMobile">
                    </li>
                  </ul>
                  <!-- is-active로 활성화 -->
                  <div v-if="showAuthNum" class="auth-number" :class="{'is-active' : showAuthNum}">
                    <div class="kb-form-fields">
                      <div class="kb-form-item">
                        <div class="kb-form-column kb-form-column-title">
                          <label class="kb-form-label">
                            <span class="kb-form-label-text">휴대폰으로 인증 요청을 보냈습니다. 앱에서 인증 진행 후 아래의 인증 확인 버튼을 눌러주세요.</span>
                          </label>
                        </div>
                        <div class="kb-form-column column-whole auth-time">
                          <span class="text">{{ countFormat() }}남음</span>
                          <button id="certificationbtn" class="kb-btn-text" style="color:grey !important;" @click="sendAuthNum" disabled>재발송</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="auth-btn-container">
                    <button v-if="showAuthNum" id="confirmbtn" class="kb-btn kb-btn-primary" @click="clickConfirmAuthNum"><span class="text">인증 확인</span></button>
                    <button v-else class="auth-btn kb-btn kb-btn-primary" @click="sendAuthNum"><span class="text">인증 요청</span></button>
                  </div>
                </div>
              </div>
            </div>

            <template v-else>
              <div class="kb-form-fields">
                <!-- kb-form-row:인증방법 -->
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">선택</strong>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <div class="kb-form-group">
                      <div class="kb-form-check kb-form-check-inline">
                        <input v-model="authMethods" type="checkbox" class="kb-form-check-input" name="chk_02" id="chk_02_01" :value="notiPfmCds.kbMobile">
                        <label for="chk_02_01" class="kb-form-check-label"><span class="text">KB 국민인증서</span><span class="sub-text">{{ userInfo.mblTelno }}</span></label>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="showAuthNum" class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">인증번호</span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <span class="kb-form-label-text">휴대폰으로 인증 요청을 보냈습니다. 앱에서 인증 진행 후 아래의 인증 확인 버튼을 눌러주세요.</span>
                  </div>
                  <div class="kb-form-column justify-content-end">
                    <div class="certification">
                      <span class="certification-time">{{ countFormat() }} 남음</span>
                      <button id="certificationbtn" class="certification-btn" style="color: grey" @click="sendAuthNum" disabled>재발송</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kb-form-buttons">
                <button v-if="showAuthNum" id="confirmbtn" class="kb-btn kb-btn-primary kb-btn-lg" @click="clickConfirmAuthNum"><span class="text">인증 확인</span></button>
                <button v-else class="kb-btn kb-btn-primary kb-btn-lg" @click="sendAuthNum"><span class="text">인증 요청</span></button>
              </div>
            </template>
          </section>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>

import {onUnmounted, reactive, ref} from 'vue';
import {notiPfmCds} from '@/assets/js/modules/noti/noti-common';
import {clearTargetInterval, getItem, lengthCheck} from '@/assets/js/util';
import {useAlert} from '@/assets/js/modules/common/alert';
import {useRoute, useRouter} from 'vue-router';
import {isLocal} from '@/assets/js/modules/common/auth';
import {useStore} from 'vuex';
import {ACT_GET_STORE_LOGIN_INFO, TOKEN_KEY} from '@/store/modules/auth/auth';
import {saveToken} from '@/assets/js/localstorage.service';
import {
  ACT_SEND_MLOGIN_CHECK_VERIFICATION,
  ACT_SEND_MLOGIN_PUSH_SEND
} from '@/store/modules/certification/certification';
import navigationUtils, {isInApp} from "@/assets/js/navigationUtils";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import AuthFormField from "@/components/auth/AuthFormField";
import {nativeLoginInfoCall} from "@/assets/js/nativeAppUtils";
import {ACT_GET_FIRST_PAGE} from "@/store/modules/menu/menu";

export default {
  name: 'Authentication',
  components: {AuthFormField, LxpMobileHeader},
  props: {
    headerTitle: String,
    userInfo: Object,
    isWarningText: {
      type: Boolean,
      default: true
    },
    isAuthentication: {
      type: Boolean,
      default: false
    },
    isUserInfo: {
      type: Boolean,
      default: true
    },
    certSuccess: {
      type: Boolean,
      default: false
    }
  },
  emits: ['goSignIn', 'update:certSuccess'],
  setup(props, {emit}) {

    const isMobile = navigationUtils.any();

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const {showMessage} = useAlert();

    const authMethods = ref([notiPfmCds.kbMobile]);

    const showAuthNum = ref(false);
    const certNo = ref('');

    const authCount = ref(0);
    const authCounterInterval = ref(null);

    const params = reactive({
      lrnerId: '',
      certNo: '',
      serialNoSn: 0
    });

    const goSignIn = () => {
      emit('goSignIn')
    }

    const sendAuthNum = () => {

      if(authMethods.value.length > 0) {
        //2차 인증 발송 성공
        authCount.value = 5 * 60;
        clearTargetInterval(authCounterInterval.value);

        authCounterInterval.value = setInterval(() => {
          authCount.value--;
          if(authCount.value <= 0) {
            // 카운터 종료 이벤트
            clearTargetInterval(authCounterInterval.value);
            showAuthNum.value = false;
          }
        }, 1000);

        certNo.value = '';
        showAuthNum.value = true;

        store.dispatch(`certification/${ACT_SEND_MLOGIN_PUSH_SEND}`,{
          lrnerId: props.userInfo.lrnerId,
          notiPfmCds: authMethods.value
        }).then(res => {
          if (res.result == "L098") {
            showMessage("사용자 정보가 존재하지 않습니다.<br> 관리자에게 문의 바랍니다.");
            authCount.value = 0;
          } else if (res.result == "L099") {
            showMessage("알림 수신 동의가 되어 있지 않아<br> 서비스 이용이 불가능합니다.<br>스타뱅킹 앱을 실행하여 알림 수신 동의 후에<br>이용 바랍니다.");
            authCount.value = 0;
          } else if (res.result == "L001") {
            showMessage("KB 국민인증서가 발급되지 않았습니다.<br> 발급 후 시도 바랍니다.");
            authCount.value = 0;
          } else if (res.result == "L301") {
            showMessage("최근 인증을 시도한 이력이 있습니다.<br> 잠시 후 시도 바랍니다.");
          } else if(res.result == "0000") {
            params.serialNoSn = res.serialNoSn;
          } else {
            showMessage("시스템 에러가 발생했습니다. 잠시 후 다시 시도 바랍니다.");
            authCount.value = 0;
          }
          var button = document.getElementById('certificationbtn');
          button.disabled = true;
          button.style.color = 'grey';
          setTimeout(function(){
            button.disabled = false;
            button.style.color = '#FFBC00';
          }, 5000);
        }).catch(e => {
          var button = document.getElementById('certificationbtn');
          button.disabled = true;
          button.style.color = 'grey';
          setTimeout(function(){
            button.disabled = false;
            button.style.color = '#FFBC00';
          }, 5000);
          console.error(e);
        });
      } else {
        showMessage({
          title: '본인확인',
          text: '인증방법을 1개 이상 선택해주세요.'
        });
      }
    }

    const clickConfirmAuthNum = () => {
      params.lrnerId = props.userInfo.lrnerId;
      store.dispatch(`certification/${ACT_SEND_MLOGIN_CHECK_VERIFICATION}`,{
        lrnerId: props.userInfo.lrnerId,
        serialNoSn: params.serialNoSn
      }).then(res => {
        if (res.resultCode == "0000") {
          if(route.name === 'PasswordReissue') {
            emit('update:certSuccess', true);
          } else {
            saveToken(TOKEN_KEY, res.authToken);
            if(isInApp){
              store.dispatch(`auth/${ACT_GET_STORE_LOGIN_INFO}`, {mode: 'store', lrnerId: params.lrnerId}).then(loginInfo => {
                if(loginInfo) {
                  nativeLoginInfoCall(JSON.stringify(loginInfo.storeLoginInfo));
                }
              });
            }

            if (route.query && route.query.redirect && route.query.redirect.replace('/app', '').length >= 2) {
              if(route.query.q ) {
                router.push({path: route.query.redirect, query: JSON.parse(window.decodeURIComponent(route.query.q))})
              } else {

                if(route.query.redirect.indexOf('/app') === 0) route.query.redirect = route.query.redirect.replace('/app', '');
                router.push({path: route.query.redirect});
              }
            }
            else {
              if(isLocal){
                store.dispatch(`menu/${ACT_GET_FIRST_PAGE}`).then(res => {
                  if(lengthCheck(res)){
                    const firstPageUrl = getItem(res).frstPageUrl;
                    router.push({path: firstPageUrl});
                  } else {
                    router.push('/main');
                  }
                }).catch(() => {
                  router.push('/main');
                });
              }else{
                router.push('/ssl/home');
              }
            }
          }
        } else {
          if (res.resultCode == "L004") {
            showMessage({
              title: 'KB모바일인증서 인증 오류',
              text: '인증이 완료되지 않았습니다.<br/>인증 완료 후 확인 바랍니다.'
            });
          } else if (res.resultCode == "L099") {
            showMessage({
              title: 'KB모바일인증서 인증 오류',
              text: '입력시간이 초과되었습니다.<br/>재 인증 하시기 바랍니다.'
            });
            authCount.value = 0;
          } else if (res.resultCode == "L301") {
            showMessage({
              title: 'KB모바일인증서 인증 오류',
              text: '최근 인증 확인을 시도한 이력이 있습니다.<br> 잠시 후 시도 바랍니다.'
            });
          } else {
            showMessage({
              title: '2차인증확인 오류',
              text: '인증이 완료되지 않았습니다.<br/>관리자에게 문의 바랍니다.'
            });
          }
        }
        var button = document.getElementById('confirmbtn');
        button.disabled = true;
        button.style.backgroundColor = 'grey';
        setTimeout(function(){
          button.disabled = false;
          button.style.backgroundColor = '#FFCC00';
        }, 5000);
      }).catch(e => {
        var button = document.getElementById('confirmbtn');
        button.disabled = true;
        button.style.backgroundColor = 'grey';
        setTimeout(function(){
          button.disabled = false;
          button.style.backgroundColor = '#FFCC00';
        }, 5000);
        console.error(e);
      })
    }

    const countFormat = () => {
      const min = (`0${Math.floor(authCount.value / 60)}`).slice(-2);
      const sec = (`0${Math.floor(authCount.value % 60)}`).slice(-2);

      return `${min}:${sec}`;
    }

    onUnmounted(() => {
      clearTargetInterval(authCounterInterval.value);
    });

    return {
      authMethods,
      showAuthNum,
      certNo,
      authCount,
      isMobile,

      goSignIn,
      sendAuthNum,
      clickConfirmAuthNum,
      countFormat,

      notiPfmCds,
    }
  }
};
</script>
