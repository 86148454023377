<template >
  <template v-if="isChecked">
      <Authentication v-if="isAuthentication"
                      header-title="로그인 2차 인증"
                      :user-info="userInfo"
                      @goSignIn="goSignIn"
                      :is-authentication="isAuthentication"
      />
      <MLoginAuthentication v-else-if="isMLoginAuthentication"
                      header-title="KB 국민인증서 인증"
                      :user-info="userInfo"
                      @goSignIn="goSignIn"
                      :is-authentication="isMLoginAuthentication"
      />
      <!-- begin::kb-login -->
      <main v-else class="kb-login">
        <div class="login-cover">
          <img v-if="isMobile" :src="bgImageName">
          <video v-else src="../../assets/lxp/videos/login.mp4" autoplay="" muted="muted" loop=""></video>
        </div>
        <div class="login-component">
          <div class="logo">
            <img src="../../assets/lxp/images/login/kb_logo.svg">
          </div>
          <div class="auth-body">
            <nav v-if="!isMobile" class="kb-nav-round-tabs"  style="padding:10px; margin-bottom: 0px;">
              <ul class="kb-nav-list">
                <li class="kb-nav-item">
                  <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu===1 }" @click="menu=1"><span class="kb-nav-text">KB국민인증서<br>로그인</span></a>
                </li>
                <li class="kb-nav-item">
                  <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu===2 }" @click="menu=2"><span class="kb-nav-text">비밀번호<br>로그인</span></a>
                </li>
              </ul>
            </nav>
            <nav v-else if="isMobile" class="kb-nav-round-tabs"  style="padding:10px; margin-bottom: 0px; border-bottom: unset;">
              <ul class="kb-nav-list" style="height: 65px;">
                <li class="kb-nav-item" >
                  <a href="#" class="kb-nav-link" style="height: 50px;" :class="{ 'is-active' : menu===1 }" @click="menu=1"><span class="kb-nav-text">KB국민인증서<br>로그인</span></a>
                </li>
                <li class="kb-nav-item">
                  <a href="#" class="kb-nav-link" style="height: 50px;" :class="{ 'is-active' : menu===2 }" @click="menu=2"><span class="kb-nav-text">비밀번호<br>로그인</span></a>
                </li>
              </ul>
            </nav>
            <div v-if="menu === 2" class="auth-forms">
              <div class="field-area">
                <div class="kb-form-input-group">
                  <input autocomplete="username" type="text" class="kb-form-input" placeholder="직원번호 입력"
                         v-model.trim="info.lrnerId"
                         @keyup.enter="enterPassword"
                  >
                  <div class="input-box"></div>
                </div>
              </div>
              <div class="field-area">
                <div class="kb-form-input-group">
                  <input autocomplete="current-password" type="password" class="kb-form-input" placeholder="비밀번호 입력"
                         v-model.trim="info.password"
                         @keyup.enter="signin"
                         id="passwordEle"
                  >
                  <div class="input-box"></div>
                </div>
              </div>
            </div>
            <div v-if="menu === 1" class="auth-forms">
              <div class="field-area">
                <div class="kb-form-input-group">
                  <input autocomplete="username" type="text" class="kb-form-input" placeholder="직원번호 입력"
                         v-model.trim="mLoginInfo.lrnerId"
                         @keyup.enter="entermblTelLastNo"
                  >
                  <div class="input-box"></div>
                </div>
              </div>
              <div class="field-area">
                <div class="kb-form-input-group">
                  <input autocomplete="current-password" type="text" value="010    -    XXXX    -" class="kb-form-input" style="padding-left: 30px; padding-right: 0px;" disabled
                  >
                  <input autocomplete="current-password" type="text" class="kb-form-input" placeholder="핸드폰 뒷자리 입력" style="padding-left: 20px;"
                         v-model.trim="mLoginInfo.mblTelLastNo"
                         @keyup.enter="mLoginsignin"
                         id="mblTelLastNoEle"
                  >
                  <div class="input-box"></div>
                </div>
              </div>
            </div>
            <button v-if="menu === 2" class="kb-btn kb-btn-primary kb-btn-lg kb-btn-login"
                    :class="{'gray-bg-Btn': isLoading, 'white-bg-Btn': isLoading}"
                    @click="signin"
            ><span class="text"><i class="mdi mdi-lock-check"></i> 비밀번호 로그인</span>
            </button>
            <button v-if="menu === 1" class="kb-btn kb-btn-primary kb-btn-lg kb-btn-login"
                    :class="{'gray-bg-Btn': isLoading, 'white-bg-Btn': isLoading}"
                    @click="mLoginsignin"
            ><span class="text"><i class="mdi mdi-lock-check"></i> KB국민인증서 로그인</span>
            </button>
            <button v-if="isInside == 1" class="kb-btn kb-btn-primary kb-btn-lg kb-btn-login"
                    :class="{'gray-bg-Btn': isLoading, 'white-bg-Btn': isLoading}"
                    @click="ssoLogin"
            ><span class="text"><i class="mdi mdi-lock-check"></i> SSO 로그인</span>
            </button>
            <div class="auth-footer">
              <div class="links">
                <!-- is-active -->
<!--                <a v-if="!isInApp" href="javascript:" :class="{ 'is-active' : isSaveIdActive }" class="link"-->
<!--                   @click="handleSaveId()"><span class="icon-check"></span>직원번호 저장</a>-->
<!--                -->
<!--                <span v-if="!isInApp" class="bar"></span>-->
                <router-link :to="{name: 'PasswordReissue'}" class="link">비밀번호 재발급</router-link>
              </div>
            </div>

          </div>

          <div v-if="isMobile && !isInApp" class="login-footer">
            <div class="login-btn-container">

<!--              <button class="kb-btn-etest" @click="showUnderconstruction()"><span class="text">eTest 응시</span></button>-->
<!--              <div class="divider"></div>-->
              <router-link :to="{name: 'EhrdAppInstall'}" class="kb-btn-etest"><span class="text">KB스타런 앱 설치</span></router-link>
            </div>
            <p class="footer-copyright mb-2">(주)국민은행 HR부(인재개발)</p>
            <p class="footer-copyright">Copyright (C) KB스타런. All Rights Reserved</p>
          </div>
        </div>

        <div v-if="!isMobile && !isInApp" class="footer-btns">
          <ol>
<!--            <li><a href="javascript:" @click="showUnderconstruction()"><span>eTest 응시</span></a></li>-->
            <li><router-link :to="{name: 'EhrdAppInstall'}"><span>KB스타런 앱 설치</span></router-link></li>
          </ol>
        </div>
        <!--        <button class="kb-btn kb-btn-white kb-btn-lg kb-btn-etest" @click="showUnderconstruction()"><span-->
        <!--            class="text">eTest 응시</span>-->
        <!--        </button>-->
      </main>
      <!-- end::kb-login -->
      <!-- begin::kb-footer -->
      <footer v-if="!isMobile" class="kb-footer" id="kb-footer">
        <div class="footer-row footer-component">
          <div class="footer-copyright">
            <p class="copyright mb-2">(주)국민은행 HR부(인재개발)</p>
            <p class="copyright">Copyright (C) KB스타런. All Rights Reserved</p>
          </div>
        </div>
      </footer>
      <!-- end::kb-footer -->
  </template>
</template>
<script>
import {onBeforeMount, reactive, ref} from 'vue';
import {useStore} from 'vuex';
import {ACT_SIGNIN_AUTH, ACT_MLOGIN_SIGNIN_AUTH, TOKEN_KEY} from '@/store/modules/auth/auth';
import {getItem, isSuccess, setParams} from '@/assets/js/util';
import Authentication from '@/components/auth/Authentication';
import MLoginAuthentication from '@/components/auth/MLoginAuthentication';
import {useAlert} from '@/assets/js/modules/common/alert';
import ApiService from '@/assets/js/api.service';
import {saveToken} from '@/assets/js/localstorage.service';
import {useRoute, useRouter} from 'vue-router';
import {setInside, setLogout} from '@/assets/js/modules/auth/auth-common';
import navigationUtils, {isInApp} from "@/assets/js/navigationUtils";
// import {getToken} from '@/assets/js/sessionstorage.service';

export default {
  name: 'Signin',
  components: {Authentication, MLoginAuthentication},
  setup() {
    const isMobile = navigationUtils.any();
    const store = useStore();
    const isLoading = ref(false);
    const menu = ref(1);

    const {showMessage, showConfirm} = useAlert();

    const info = reactive({
      lrnerId: '',
      password: '',
    });

    const mLoginInfo = reactive({
      lrnerId: '',
      mblTelLastNo: '',
    });

    const userInfo = reactive({
      lrnerNm : '',
      lrnerId : '',
      brdt : '',
      mblTelno : '',
      emlAddr : '',
      deptNm: '',
      isLiivYn: '',
    });

    const isChecked = ref(false);
    const isInside = ref(0);

    const isSaveIdActive = ref(false);
    const isAuthentication = ref(false);
    const isMLoginAuthentication = ref(false);

    const validate = () => {
      let result = false;
      isLoading.value = false;

      if(!info.lrnerId) {
        showMessage('직원번호를 입력해주세요.');
      } else if(!info.password) {
        showMessage('비밀번호를 입력해주세요.');
      } else{
        result = true;
        isLoading.value = true;
      }

      return result;
    }

    const mLoginvalidate = () => {
      let result = false;
      isLoading.value = false;

      if(!mLoginInfo.lrnerId) {
        showMessage('직원번호를 입력해주세요.');
      } else if(!mLoginInfo.mblTelLastNo) {
        showMessage('핸드폰 뒷자리를 입력해주세요.');
      } else{
        result = true;
        isLoading.value = true;
      }

      return result;
    }

    const route = useRoute();
    const router = useRouter();

    const enterPassword = () =>{
        document.getElementById("passwordEle").focus();
    }

    const entermblTelLastNo = () =>{
      document.getElementById("mblTelLastNoEle").focus();
    }

    const signin = () => {
      document.getElementById("passwordEle").blur();
      if (isLoading.value) return;
      isLoading.value = true;

       if(validate()) {
        store.dispatch(`auth/${ACT_SIGNIN_AUTH}`, info).then(res => {

          // console.log(res)
          if(process.env.NODE_ENV === 'local'){
            if(isSuccess(res)) {
              saveToken(TOKEN_KEY, res.authToken);
              if (route.query.redirect && !route.query.redirect.includes('/signin')) {
                if(route.query.q ) {
                  router.push({path: route.query.redirect, query: JSON.parse(window.decodeURIComponent(route.query.q))})
                } else {
                  router.push({path: route.query.redirect});
                }
              }else {
                router.push('/main');
              }
            } else {
              showMessage('아이디 또는 비밀번호를 다시 확인해주세요.');
            }
          }else{
            if(isSuccess(res)) {
              setParams(userInfo,  getItem(res));
              isAuthentication.value = true;
            } else if(res.result.number === 301) {
              const msg = 'ID 또는 비밀번호가 일치하지 않습니다. <br/> 로그인 정보를 확인하시고 다시 시도하여 주십시오.';
              let msgText = '';

              if(res.lgnFailCnt > 2 && res.lgnFailCnt < 4) {
                msgText = `${msg} 로그인 정보를 확인하시고 다시 시도하여 주십시오. 부여된 권한에 위반되는 행위는 <정보통신망 이용촉진 및 정보보호등에 관한 법률>에 의하여 형사상 책임등의 조치가 있을 것입니다! (오류횟수 : ${res.lgnFailCnt}회)`;
              } else if(res.lgnFailCnt > 5){
                msgText = `비밀번호 오류입력 횟수가 5회 이상입니다. <br/> 보안카드 로그인 또는 비밀번호 찾기를 통해 로그인 하시면 초기화 됩니다. 부여된 권한에 위반되는 행위는 <br/> <정보통신망 이용촉진 및 정보보호등에 관한 법률>에 의하여 형사상 책임등의 조치가 있을 것입니다!`;
              } else {
                msgText = `${msg} (오류횟수 : ${res.lgnFailCnt}회)`;
              }

              showMessage(msgText);
            } else if(res.result.number === 302) {
              showMessage({
                text: res.result.message,
                callback: () => {
                  router.push({name: 'PasswordReissue'});
                }
              });
            } else {
              showMessage('아이디 또는 비밀번호를 다시 확인해주세요.');
            }
          }
          isLoading.value = false;
        }).catch((e) => {
          console.log(e);
          alert('error');
          isLoading.value = false;
        });
      }

    };

    const mLoginsignin = () => {
      document.getElementById("mblTelLastNoEle").blur();
      if (mLoginvalidate()) {
        console.log("signin 도착");

        store.dispatch(`auth/${ACT_MLOGIN_SIGNIN_AUTH}`, mLoginInfo).then(res => {
          if(process.env.NODE_ENV === 'local'){
            if(isSuccess(res)) {
              saveToken(TOKEN_KEY, res.authToken);
              if (route.query.redirect && !route.query.redirect.includes('/signin')) {
                if(route.query.q ) {
                  router.push({path: route.query.redirect, query: JSON.parse(window.decodeURIComponent(route.query.q))})
                } else {
                  router.push({path: route.query.redirect});
                }
              }else {
                router.push('/main');
              }
            } else {
              showMessage('아이디 또는 비밀번호를 다시 확인해주세요.');
            }
          }else{
            if(isSuccess(res)) {
              setParams(userInfo,  getItem(res));
              isMLoginAuthentication.value = true;
            } else if(res.result.number === 301) {
              const msg = 'ID 또는 핸드폰번호가 일치하지 않습니다. <br/> 로그인 정보를 확인하시고 다시 시도하여 주십시오.';
              let msgText = '';

              if(res.lgnFailCnt > 2 && res.lgnFailCnt < 4) {
                msgText = `${msg} 로그인 정보를 확인하시고 다시 시도하여 주십시오. 부여된 권한에 위반되는 행위는 <정보통신망 이용촉진 및 정보보호등에 관한 법률>에 의하여 형사상 책임등의 조치가 있을 것입니다! (오류횟수 : ${res.lgnFailCnt}회)`;
              } else if(res.lgnFailCnt > 5){
                msgText = `오류입력 횟수가 5회 이상입니다. <br/> 보안카드 로그인 또는 비밀번호 찾기를 통해 로그인 하시면 초기화 됩니다. 부여된 권한에 위반되는 행위는 <br/> <정보통신망 이용촉진 및 정보보호등에 관한 법률>에 의하여 형사상 책임등의 조치가 있을 것입니다!`;
              } else {
                msgText = `${msg} (오류횟수 : ${res.lgnFailCnt}회)`;
              }

              showMessage(msgText);
            } else if(res.result.number === 302) {
              showMessage({
                text: res.result.message,
                callback: () => {
                  router.push({name: 'PasswordReissue'});
                }
              });
            } else {
              showMessage('아이디 또는 핸드폰번호를 다시 확인해주세요.');
            }
          }
          isLoading.value = false;
        }).catch((e) => {
          console.log(e);
          alert('error');
          isLoading.value = false;
        });
        // showMessage('직원번호를 입력해주세요.ㅇㅇㅇ');
        // userInfo.lrnerId = '3900156'
        // userInfo.lrnerNm = '유환주'
        // userInfo.brdt = '930920'
        // userInfo.deptNm = 'HR부(인재개발)'
        // userInfo.emlAddr = '*****@kbfg.com'
        // userInfo.mblTelno = '010-8530-7764'
        // isMLoginAuthentication.value = true;
      }
      // showMessage('직원번호를 입력해주세요.');
    }

    const showUnderconstruction = () => {
      return alert('준비중입니다.');
    };

    const handleSaveId = () => {
      isSaveIdActive.value = !isSaveIdActive.value;
      showUnderconstruction();
    };

    const goSignIn = () => {
      info.lrnerId = '';
      info.password = '';
      mLoginInfo.lrnerId = '';
      mLoginInfo.mblTelLastNo = '';

      isAuthentication.value = false;
      isMLoginAuthentication.value = false;
    }

    const ssoLogin = () => {
      // 내부 sso 는 한번만..
      const ssoCount = window.localStorage.getItem('kbssofailno');
      if ( ssoCount ) {
        window.localStorage.removeItem('kbssofailno')
        return
      }
      window.localStorage.setItem('kbssofailno', '0');

      if (route.query.redirect && !route.query.redirect.includes('/signin')) {
        if(route.query.q ) {
          window.location.href = '/sso/business?redirect=' + route.query.redirect + '&q=' + route.query.q;
        } else {
          window.location.href = '/sso/business?redirect=' + route.query.redirect;
        }
      } else {
        window.location.href = '/sso/business';
      }
    }
    console.log("check mob env" , isMobile, isInApp);

    if (isMobile && !isInApp) {

      showConfirm({
        text : `현재 모바일 Web환경으로 접속하였습니다.</br> 스타런 앱에서 보다 편리하게 이용하실 수 있습니다.<br/>설치 화면으로 이동하시겠습니까?`,
        callback: () => {
          // window.location.href = '/app/ehrd/install';
          router.push({path:'/ehrd/install'});
        }
      });
    }

    onBeforeMount(async () => {
      // SSO 연동 후 로그아웃 했을 때 직번, 비밀번호 로그인 할 수 있게 처리
      const isLogout = store.state.auth.isLogout // getToken('isLogout');

      console.log("logout check", isLogout);
      await setLogout(false)

      if(isLogout) {
        isChecked.value = true;
        if( !store.state.auth.isInside) {
          ApiService.post('/v1/app/ips/mngs', {}).then(res => {
            if(isSuccess(res)) {
              setInside(1)
              isInside.value = 1
            } else {
              setInside(0);
            }
          }).catch(e => {
            console.error(e);
          });
        } else {
          isInside.value = store.state.auth.isInside
        }
      } else {
        // 내부망 IP 체크 하여 SSO 연동 또는 로그인 페이지 이동
        if( !store.state.auth.isInside) {
          ApiService.post('/v1/app/ips/mngs', {}).then(res => {
            if(isSuccess(res)) {
              setInside(1)
              ssoLogin()
            } else {
              setInside(0)
              isChecked.value = true
            }
          }).catch(e => {
            isChecked.value = false
            console.error(e)
          })
        } else {
          isInside.value = store.state.auth.isInside
        }
    }
  })

    /* 매번 들어올때마다 이미지 변경 bg_0~3.jpg*/
    const randomNumber = Math.floor(Math.random() * 4);
    const bgImageName = ref(require(`../../assets/lxp/mobile/images/login/bg_${randomNumber}.jpg`));

    return {
      isChecked,
      isInside,
      isLoading,
      info,
      mLoginInfo,
      userInfo,
      isSaveIdActive,
      isAuthentication,
      isMLoginAuthentication,
      isMobile,
      bgImageName,
      enterPassword,
      entermblTelLastNo,
      signin,
      mLoginsignin,
      showUnderconstruction,
      handleSaveId,
      goSignIn,
      ssoLogin,
      isInApp,
      menu
    };
  },
};
</script>
<style scoped>
.kb-login .footer-btns ol li:first-child{}
.kb-login .footer-btns ol li{width: 100% !important;}
</style>